import { Button, Select } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import styles from '../index.less';
import type { DashBoardInstance } from './dashboard';
import Dashboard, { dashboardStore } from './dashboard';
import { SystemType } from '../components/noticeBadge/constant';
import NoticeBadge from '../components/noticeBadge';
import UnionGuide from '../../utils/layout/unionGuide';
import PosExpireTenantMsgModal from './dashboard/components/posExpireModal';

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-vendor-pos',
  },
});
layoutStore.handleInit();

interface IState {
  warehouseList: WareHouse[];
  currentWareHouse: string;
  step: number;
  visible: boolean;
}

interface IHomeMessage {
  switchStatus: number;
  detailVoList: EachDetail[];
}

interface EachDetail {
  desc: string;
  noticeType: number;
}

interface WareHouse {
  shopAndWarehouseName: string;
  shopAndWarehouseId: string;
  shopId: string;
  warehouseId: string;
  openStockManagement: boolean;
  autoRestStock: boolean;
}

interface Operation {
  id: string | number;
  name: string;
  callback?: () => void;
}

interface HomePageType {
  current: boolean;
  homePageType: number;
  indexUrl: string;
  indexUserName: string;
}

// 导航 示例
export default class LayoutTest extends React.Component {
  public state: IState = {
    warehouseList: [],
    currentWareHouse: '',
    visible: false, // 引导visible
    step: 1, // 引导当前步骤
  };

  async componentDidMount(): Promise<void> {
    this.queryWarehouse();
    console.log('componentDidMount....', this);
    try {
      await this.queryHomePageTypes();

      // const vendorPosGuide = localStorage.getItem('vendorPosGuide');
      // if (!vendorPosGuide) {
      //   this.setState({ visible: true });
      // }
    } catch (e) {
      console.log(e);
    }
  }

  public dashBoardRef = React.createRef<DashBoardInstance>();

  public queryWarehouse(): void {
    request({ url: api.getWarehouses })
      .then((res: { data?; status: string; }) => {
        if (!res.data || res.data.length === 0) {
          return;
        }
        sessionStorage.setItem('currentWareHouse', res.data[0].shopAndWarehouseId);
        this.setState({
          warehouseList: res.data,
          currentWareHouse: res.data[0].shopAndWarehouseId,
        }, () => {
          layoutStore.setSrcParams(this.getSrcParams());
        });
      });
  }

  public queryHomePageTypes = async(): Promise<void> => {
    const res: BaseData<HomePageType[]> = await request({ url: api.homePageTypes });
    if (res.data && res.data.length > 1) {
      this.setState({ step: 1 });
    }
  };

  public handleWarehouseChange(value: string): void {
    this.setState({ currentWareHouse: value }, () => {
      layoutStore.setSrcParams(this.getSrcParams());
    });
    sessionStorage.setItem('currentWareHouse', value);

    // 切换仓库后改变首页数据
    dashboardStore.getShopData();
    dashboardStore.getOrderData();
    dashboardStore.getCoordinationAndDeliver();

    // top.egenie.openTab(`/ejl-pos/saleOrder?shopAndWarehouseId=${value}`, 506, '销售开单', 'audit');
    // 切换仓库后关闭所有iframe  留在首页
    const iframes = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i++) {
      const element = iframes[i];
      if (element && element.id === '0') {
        continue;
      }
      window.egenie.closeTab(element.id);
    }
  }

  public userInfoLeft = (): JSX.Element => {
    const {
      currentWareHouse,
      warehouseList,
    } = this.state;
    return (
      <div>
        <NoticeBadge systemType={SystemType.POS_PC}/>
        <Select
          bordered={false}
          onChange={this.handleWarehouseChange.bind(this)}
          style={{ width: 120 }}
          value={currentWareHouse}
        >
          {warehouseList.map((item) => (
            <Select.Option
              key={item.shopAndWarehouseId}
              value={item.shopAndWarehouseId}
            >
              {item.shopAndWarehouseName}
            </Select.Option>
          ))}

        </Select>
        <span className={styles.splitIcon}>
          |
        </span>
      </div>
    );
  };

  public userInfoRight = (): Operation[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
    ];
  };

  public getSrcParams = () => {
    const result = [];

    const menus = [
      506, // 销售开单
      507, // 退货开单
      508, // 开单查询
      510, // 退货查询
      645, // POS入库
      646, // POS出库
      647, // POS盘点
      648, // POS调拨
      2108, // POS客服应收
      253, // 客户管理
      310, // 选款订单
      60088, // 客户商品查询
      60085, // 销退合一
      4001, // 协同销售单
      2110, // 资金收入报表
      60219, // 库存查询
      2713, // 我要推广
      2714, // 推广效果
    ];
    sessionStorage.setItem('warehouseMenu', JSON.stringify(menus));
    menus.forEach((item) => {
      result.push({
        id: item,
        params: `shopAndWarehouseId=${this.state.currentWareHouse}`,
      });
    });
    return result;
  };

  public handleChangeStep = (e): void => {
    e.stopPropagation();
    const step = this.state.step;

    if (step === 2 && !layoutStore?.userInfo?.tenantType?.includes('100005')) {
      this.handleCloseGuide();
    }

    if (step !== 4) {
      this.setState({ step: step + 1 });
      if (step === 1) {
        this.dashBoardRef.current.scrollToShop();
      } else if (step === 2) {
        this.dashBoardRef.current.scrollToOrder();
      }
    } else {
      this.handleCloseGuide();
    }
  };

  public handleCloseGuide = (): void => {
    this.setState({ visible: false });
    localStorage.setItem('vendorPosGuide', 'true');
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/erpLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  public closeNoticeModal = () => {
    this.setState({ noticeVisible: false });
  };

  render(): JSX.Element {
    const {
      step,
      visible,
    } = this.state;
    return (
      <div>
        <LayoutGuide
          defaultDashboard={(
            <Dashboard
              layoutStore={layoutStore}
              ref={this.dashBoardRef}
            />
          )}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
        <UnionGuide store={layoutStore}/>
        <PosExpireTenantMsgModal/>
        {visible && (
          <div
            className={styles.mask}
            onClick={this.handleChangeStep}
          >
            {step === 1 && (
              <>
                <img
                  className={styles.guideFirst}
                  src={`${api.oss}/images/guideOne.png`}
                />
                <div
                  className={styles.jumpOverOne}
                  onClick={this.handleCloseGuide}
                >
                  跳过
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <img
                  className={styles.guideSecond}
                  src={`${api.oss}/images/guideTwo.png`}
                />
                <div
                  className={styles.jumpOverTwo}
                  onClick={this.handleCloseGuide}
                >
                  跳过
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <img
                  className={styles.guideThird}
                  src={`${api.oss}/images/guideThree.png`}
                />
                <div
                  className={styles.jumpOverThree}
                  onClick={this.handleCloseGuide}
                >
                  跳过
                </div>
              </>
            )}
            {step === 4 && (
              <img
                className={styles.guideFourth}
                src={`${api.oss}/images/guideFour.png`}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const OrderNotice: React.FC<{ closeNotice: () => void; data: EachDetail[]; }> = (props) => {
  return (
    <div className={styles.noticeWrapper}>
      <div>
        <div className={styles.noticeTitle}>
          <div>
            <img
              className={styles.bell}
              src={`${api.oss}/images/bell.png`}
            />
            <span>
              依链代发订单
            </span>
          </div>
          <CloseOutlined
            className={styles.closeIcon}
            onClick={props.closeNotice}
          />
        </div>
        <div className={styles.noticeContent}>
          {
            Array.isArray(props.data) && props.data.length > 0 ? props.data.map((item) => (
              <div key={item.desc}>
                {item.desc}
              </div>
            )) : null
          }
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          onClick={() => {
            top.egenie.openTab('/egenie-erp-home/notificationCenter', 'notificationCenter', '通知中心');
          }}
          size="small"
          type="primary"
        >
          查看详情
        </Button>
      </div>
    </div>
  );
};
