import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type DashboardStore from '../../store';
import styles from './index.less';

export const YilianShop: React.FC<{ store: DashboardStore; }> = observer((props) => {
  const { shopData } = props.store;
  return (
    <div className={styles.yilianShop}>
      <div className={styles.title}>
        依链小店
      </div>
      <Row
        className={styles.statisticWrapper}
      >
        <Col
          className={styles.mb16}
          span={6}
        >
          <div
            onClick={() => {
              const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];
              top.egenie.openTab(`/egenie-vendor-pos/productList/index?shopIds=${shopId}&status=3`, 2693, '商品列表');
            }}
            style={{ cursor: 'pointer' }}
          >
            <EachStatistic
              num={shopData.saleGoodsNum}
              title="依链在售商品(详款) >"
            />
          </div>
        </Col>
        <Col
          className={styles.mb16}
          span={6}
        >
          <EachStatistic
            num={shopData.reviewGoodsNum}
            tip="商品需要先通过依链平台审核，才能在平台进行销售"
            title="审核中商品(详款)"
          />
        </Col>
        <Col span={6}>
          <EachStatistic
            num={shopData.noPassGoodsNum}
            tip="不符合依链平台规则的商品，请及时修改"
            title="审核未通过(详款)"
          />
        </Col>
        <Col span={6}>
          <EachStatistic
            num={shopData.simpleGoodsNum}
            tip="简款仅用于档口开单，因缺少主图，详情图，属性等信息无法上架到依链平台进行销售"
            title="简款"
          />
        </Col>
        <Col span={6}>
          <EachStatistic
            num={shopData.todaySaleSkuNum}
            ratio={shopData.skuNumRatio}
            ratioFlag={shopData.saleAmountRatioFlag}
            title="依链销量"
            yesterdayNum={shopData.yesterdaySaleSkuNum}
          />
        </Col>
        <Col span={6}>
          <EachStatistic
            num={shopData.todaySaleAmount}
            ratio={shopData.saleAmountRatio}
            ratioFlag={shopData.saleAmountRatioFlag}
            title="依链销售金额"
            yesterdayNum={shopData.yesterdaySaleAmount}
          />
        </Col>
        <Col span={6}>
          <EachStatistic
            num={shopData.todayCustomerNum}
            ratio={shopData.customerNumRatio}
            ratioFlag={shopData.customerNumRatioFlag}
            title="新增客户数"
            yesterdayNum={shopData.yesterdayCustomerNum}
          />
        </Col>
      </Row>
    </div>
  );
});

interface EachStatisticaProps{
  title: string;
  num: number | string;
  tip?: string;
  yesterdayNum?: number;
  ratio?: string;
  ratioFlag?: number;
  numColor?: string;
}

const EachStatistic: React.FC<EachStatisticaProps> = (props) => {
  const { title, num, yesterdayNum, ratio, tip, ratioFlag, numColor } = props;
  return (
    <div className={styles.eachStatistic}>
      <div className={styles.eachStatisticTitle}>
        {title}
        {
          tip ? (
            <Popover content={tip}>
              <InfoCircleOutlined className={styles.tip}/>
            </Popover>
          ) : null
        }
      </div>
      <div
        className={styles.eachStatisticNum}
        style={{ color: numColor }}
      >
        {num}
      </div>

      {
        typeof (yesterdayNum) === 'number' ? (
          <div className={styles.yesterdayInfo}>
            <span className={styles.yesterdayText}>
              昨日:
              {yesterdayNum}
            </span>
            <br/>
            <span className={styles.sequential}>
              环比:
            </span>
            <span className={styles.ratioWrapper}>
              {ratioFlag ? <TriAngle flag={ratioFlag > 0}/> : null}
              <span style={{ color: ratioFlag > 0 ? '#02c190' : 'red' }}>
                {ratio}
              </span>
            </span>
          </div>
        ) : null
      }
    </div>
  );
};

const TriAngle: React.FC<{ flag: boolean; }> = (props) => {
  return (
    <div className={props.flag ? styles.triAngleUp : styles.triAngleDown}/>
  );
};

export const YilianOrder: React.FC<{ store: DashboardStore; }> = observer((props) => {
  const { posOrderData } = props.store;
  return (
    <div className={styles.yilianOrder}>
      <div className={styles.title}>
        依链开单
      </div>
      <Row
        className={styles.statisticWrapper}
      >
        {
          posOrderData.saleNumFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todaySaleNum}
                ratio={posOrderData.saleRatio}
                ratioFlag={posOrderData.saleRatioFlag}
                title="销售数量"
                yesterdayNum={posOrderData.yesterdaySaleNum}
              />
            </Col>
          )
            : null
        }
        {
          posOrderData.saleOrderNumFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todaySaleOrderNum}
                ratio={posOrderData.saleOrderRatio}
                ratioFlag={posOrderData.saleOrderRatioFlag}
                title="销售单数"
                yesterdayNum={posOrderData.yesterdaySaleOrderNum}
              />

            </Col>
          ) : null
        }
        {
          posOrderData.saleAmountFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todaySaleAmount}
                ratio={posOrderData.saleAmountRatio}
                ratioFlag={posOrderData.saleAmountRatioFlag}
                title="销售金额"
                yesterdayNum={posOrderData.yesterdaySaleAmount}
              />
            </Col>
          ) : null
        }
        {
          posOrderData.returnNumFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todayReturnNum}
                ratio={posOrderData.returnRatio}
                ratioFlag={posOrderData.returnRatioFlag}
                title="退货数量"
                yesterdayNum={posOrderData.yesterdayReturnNum}
              />
            </Col>
          ) : null
        }
        {
          posOrderData.returnOrderFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todayReturnOrderNum}
                ratio={posOrderData.returnOrderRatio}
                ratioFlag={posOrderData.returnOrderRatioFlag}
                title="退货单数"
                yesterdayNum={posOrderData.yesterdayReturnOrderNum}
              />
            </Col>
          ) : null
        }
        {
          posOrderData.returnAmountFlag ? (
            <Col span={4}>
              <EachStatistic
                num={posOrderData.todayReturnAmount}
                ratio={posOrderData.returnAmountRatio}
                ratioFlag={posOrderData.returnAmountRatioFlag}
                title="退货金额"
                yesterdayNum={posOrderData.yesterdayReturnAmount}
              />
            </Col>
          ) : null
        }
      </Row>
    </div>
  );
});

export const NotCheckAmomunt: React.FC<{ store: DashboardStore; }> = observer((props) => {
  const { arrearsInfo } = props.store;
  return (
    <div className={styles.notCheckAmount}>
      <div className={styles.title}>
        未核销欠款统计
      </div>
      <Row
        className={styles.statisticWrapper}
        gutter={12}
      >
        <Col span={12}>
          <EachStatistic
            num={arrearsInfo.arrearsCustomerNum}
            numColor="red"
            title="欠款客户数"
          />
        </Col>
        <Col span={12}>
          <EachStatistic
            num={arrearsInfo.arrearsPrice}
            numColor="red"
            title="欠款总金额"
          />
        </Col>
      </Row>
    </div>
  );
});
