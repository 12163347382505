import React, { useEffect } from 'react';
import styles from './index.less';
import { api } from '../../../../../utils/api';
import moment from 'moment';
import { observer } from 'mobx-react';
import type DashboardStore from '../../store';
import type { ILayoutStore } from '../../../../../utils/layout';

export const Header: React.FC<{ store: DashboardStore; layoutStore: ILayoutStore; }> = observer((props) => {
  const { coordinationData, deliverData, sellerShopId, getSellerShopIdQuery, brandData } = props.store;
  const { isJoinClothesUnion, showBannerFlag } = props.layoutStore;

  const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];
  useEffect(() => {
    getSellerShopIdQuery();
  }, [shopId]);
  return (
    <div className={styles.header}>
      <div
        className={styles.headerLeft}
        style={{ backgroundImage: `url(${api.oss}/images/statisticsBgSm.png)` }}
      >
        <div className={styles.headerCollaborate}>
          <div className={styles.headerTitle}>
            依链协同
          </div>
          <div className={styles.headerContent}>
            <EachStatistic
              label="待到店拿货"
              num={coordinationData.pickUpNum}
              onClick={() => {
                window.top.egenie.openTab('/egenie-vendor-pos/yilianCollaborativeOrder?orderStatus=0', 4001, '协同销售单', 'dcldd');
              }}
            />
            <EachStatistic
              label="待审核退货"
              num={coordinationData.returnNum}
              onClick={() => {
                window.top.egenie.openTab('/egenie-ts-scl/collaborativeReturnOrder?orderState=0', 60003, '协同退货单', 'dcldd');
              }}
            />
            <EachStatistic
              label="待退款"
              num={coordinationData.refundNum}
              onClick={() => {
                window.top.egenie.openTab('/egenie-ts-scl/collaborativeReturnOrder?orderState=4', 60003, '协同退货单', 'dcldd');
              }}
            />
            <div style={{ flex: 1 }}/>
          </div>
        </div>
        <div
          className={styles.headerCollaborate}
          style={{ marginBottom: 0 }}
        >
          <div className={styles.headerTitle}>
            依链代发
          </div>
          <div className={styles.headerContent}>
            <EachStatistic
              label="待审核"
              num={deliverData.unCheckToWarehouse || 0}
              onClick={() => {
                window.top.egenie.openTab(`/egenie-ts-oms/order?is_checked-3-1=false&is_suspended-3-1=false&shop_id-4-10=${sellerShopId}`, 505, '订单处理', 'icon_ddcl');
              }}
            />
            <EachStatistic
              label="待发货"
              num={deliverData.unDelivered || 0}
              onClick={() => {
                window.top.egenie.openTab(`/egenie-ts-oms/order?courier_print_mark_state-4-14=1&is_suspended-3-1=false&shop_id-4-10=${sellerShopId}`, 505, '订单处理', 'icon_ddcl');
              }}
            />
            <EachStatistic
              label="待退货"
              num={deliverData.unConfirmArrived || 0}
              onClick={() => {
                window.top.egenie.openTab(`/egenie-ts-oms/aftersale?arrival_state-4-1=0,3&shop_id-4-1=${sellerShopId}`, 709, '售后单处理', 'after_sale');
              }}
            />
            <EachStatistic
              label="发货超时告警"
              num={deliverData.aboutToOvertime || 0}
              onClick={() => {
                const endTime = moment().subtract(48, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss');
                window.top.egenie.openTab(`/egenie-ts-oms/order?courier_print_mark_state-4-14=1&date=sale_order_status.pay_time,,${endTime}&is_suspended-3-1=false&shop_id-4-10=${sellerShopId}`, 505, '订单处理', 'icon_ddcl');
              }}
              showWarn
            />
          </div>
        </div>
      </div>
      {isJoinClothesUnion && (
        <div className={styles.headerCenter}>
          <div className={styles.titleContainer}>
            <div className={styles.bigTitle}>
              不缺货联盟
            </div>
            <div className={styles.smallTitle}>
              {brandData.currentTime}
              更新
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={styles.flex}
            >
              <div className={`${styles.row} ${styles.mb8}`}>
                <div
                  className={styles.smallTitle}
                  onClick={() => {
                    window.open('/egenie-clothes-union/salesAnalysis');
                  }}
                >
                  电商已销缺货率(件)
                </div>
                <img
                  className={styles.arrowBlack}
                  src={`${api.oss}/images/arrowBlack.png`}
                />
              </div>
              <div className={styles.red}>
                {brandData.saleLackNum}
              </div>
            </div>
            <div
              className={styles.flex}
            >
              <div className={`${styles.row} ${styles.mb8}`}>
                <div
                  className={styles.smallTitle}
                  onClick={() => {
                    window.open('/egenie-clothes-union/salesAnalysis');
                  }}
                >
                  电商采购量(件)
                </div>
                <img
                  className={styles.arrowBlack}
                  src={`${api.oss}/images/arrowBlack.png`}
                />
              </div>
              <div className={styles.blue}>
                {brandData.totalPlanNums}
              </div>
            </div>
          </div>

        </div>
      )}
      {
        (!isJoinClothesUnion && showBannerFlag) ? renderClothesUnion() : (
          <img
            className={styles.shjImage}
            onClick={() => {
              window.open('/egenie-webapp-pay/accountCenter/financeDetail');
            }}
            src={`${api.oss}/images/posFinanceBanner.png`}
          />
        )
      }
    </div>
  );
});

const linkToClothUnion = () => {
  window.open('/egenie-clothes-union/active/index');
};

const renderClothesUnion = (): JSX.Element => {
  return (
    <div
      className={styles.clothesUnionBox}
      data-id={JSON.stringify({
        name: '不缺货联盟活动页',
        jump: '/egenie-clothes-union/active/index',
        businessType: 'union',
      })}
      onClick={linkToClothUnion}
    >
      <img
        className={styles.clothesUnionContent}
        src={`${api.oss}/images/guanggao1.png`}
      />
      <img
        className={styles.clothesUnionBg}
        src={`${api.oss}/images/guanggao1icon.png`}
      />
    </div>
  );
};

const EachStatistic: React.FC<{ num: number; label: string; onClick: () => void; showWarn?: boolean; }> = (props) => {
  return (
    <div
      className={styles.eachStatistic}
      onClick={props.onClick}
    >
      <div className={styles.eachStatisticNum}>
        {props.num}
        单
      </div>
      <div className={styles.labelWrapper}>
        {props.showWarn ? (
          <span className={styles.warnIcon}>
            !
          </span>
        ) : null}
        {props.label}
      </div>
    </div>
  );
};
