import { observer, Provider } from 'mobx-react';
import React, { useEffect } from 'react';
import { ContactService, Notice, RelatedProduct, SevenDaysStatics, TopStatics } from '../../components/index';
import styles from './index.less';
import DashboardStore from './store';
import { Header, NotCheckAmomunt, QuickOperation, YilianOrder, YilianShop } from './components';
import type { ILayoutStore } from '../../../utils/layout';
import { api } from '../../../utils';

const reference = [
  {
    name: 'Lodop打印组件下载',
    url: `${api.oss}/others/CLodop_Setup_for_Win64NT_4.158EN.zip`,
    img: 'lodop.png',
    download: 'Lodop打印组件',
  },
];

export const dashboardStore = new DashboardStore();

export interface DashBoardInstance {
  scrollToShop: () => void;
  scrollToOrder: () => void;
}

const Dashboard = React.forwardRef((props: { layoutStore: ILayoutStore; }, ref) => {
  useEffect(() => {
    // 获取最新的warehouseId
    setTimeout(() => {
      dashboardStore.onQuery();
    }, 800);
  }, []);

  const dashBoardRef = React.useRef<HTMLDivElement>();

  React.useImperativeHandle(ref, () => ({
    scrollToShop: () => {
      dashBoardRef.current.scrollTo({ top: 290 });
    },
    scrollToOrder: () => {
      dashBoardRef.current.scrollTo({ top: 572 });
    },
  }));

  const {
    shortCutStore,
    isShowHome,
  } = dashboardStore;
  return (
    <div
      className={styles.dashboard}
      ref={dashBoardRef}
    >
      {
        isShowHome ? <PosHomePic/> : (
          <Provider dashboardStore={dashboardStore}>
            <Header
              layoutStore={props.layoutStore}
              store={dashboardStore}
            />

            <div className={styles.panel}>
              <QuickOperation store={shortCutStore}/>
            </div>
            <div className={styles.panel}>
              <YilianShop store={dashboardStore}/>
              <Notice
                height={282}
                platformType={2}
              />
            </div>
            <div className={styles.panel}>
              {
                props.layoutStore.userInfo.tenantType?.includes('100002') ? (
                  <div className={styles.orderAmount}>
                    <YilianOrder store={dashboardStore}/>
                    <NotCheckAmomunt store={dashboardStore}/>
                  </div>
                ) : null
              }
            </div>
            <div className={styles.panel}>
              <SevenDaysStatics/>
              <TopStatics/>
            </div>
            <div className={styles.panel}>
              <RelatedProduct platformType={2}/>
              <ContactService/>
            </div>
          </Provider>
        )
      }

    </div>
  );
});

const PosHomePic: React.FC = () => {
  return (
    <div className={styles.posHomePic}>

      <div className={styles.textContainer}>
        <div className={styles.textWrapper}>
          <div className={styles.row_1}>
            Welcome
          </div>
          <div className={styles.row_2}>
            欢迎进入POS管理系统
          </div>
          <div className={styles.downloadLinkContent}>
            {reference.map((item) => (
              <a
                className={styles.item}
                download={item.download}
                href={item.url}
                key={item.name}
                rel="noreferrer"
                target={!item.download ? '_blank' : undefined}
              >
                <img
                  className={styles.imgIcon}
                  src={`${api.oss}/images/${item.img}`}
                />
                <span>
                  {item.name}
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>

      <img
        className={styles.img}
        src={`${api.oss}/images/posHome.png`}
      />
    </div>
  );
};

export default observer(Dashboard);
