import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import css from './layoutMenu.less';
import type { LayoutStore } from './layoutStore';

const UnionGuide = observer((props: { store: LayoutStore; }) => {
  const { homePageType, isJoinClothesUnion } = props.store;

  const [
    showGuide,
    setShowGuide,
  ] = useState<boolean>(false);

  useEffect(() => {
    const egenieErpHomeHasUnionGuideLog = localStorage.getItem('egenieErpHomeHasUnionGuideLog');
    console.log(!egenieErpHomeHasUnionGuideLog, Number(homePageType), isJoinClothesUnion);
    if (!egenieErpHomeHasUnionGuideLog && Number(homePageType) && isJoinClothesUnion) {
      setShowGuide(true);
    }
  }, [
    isJoinClothesUnion,
    homePageType,
  ]);

  const oncancel = () => {
    setShowGuide(false);
    localStorage.setItem('egenieErpHomeHasUnionGuideLog', '1');
  };

  const jumpToUnion = () => {
    oncancel();
    if (homePageType === 1) {
      window.open(`/egenie-clothes-union/members?type=${homePageType}`);
      return;
    }
    window.open(`/egenie-clothes-union/salesAnalysis?type=${homePageType}`);
  };

  if (!showGuide) {
    return <div/>;
  }
  return (
    <div className={css.unionGuideMask}>
      <img
        alt=""
        className={css.unionGuideImg}
        src={process.env.REACT_APP_OSS + (homePageType === 1 ? 'pc/ts/egenie-clothes-union/erpHomeUnionGuideTwo.png' : 'pc/ts/egenie-clothes-union/erpHomeUnionGuide.png')}
      />
      <div className={css.buttonBox}>
        <div
          className={css.quiteButton}
          onClick={oncancel}
        >
          知道了
        </div>
        <div
          className={css.jumpButton}
          onClick={jumpToUnion}
        >
          去看看
        </div>
      </div>
    </div>
  );
});

export default UnionGuide;
