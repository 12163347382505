import { Modal, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Menu } from '../../../../components/shortcut/interface';
import type ShortCutStore from '../../../../components/shortcut/store';

interface Props {
  store: ShortCutStore;
}

const listPanel = (list: Menu[], type: string, parentId?: number, tabIndex?: number, callback?: (item: Menu, parentId?: number, index?: number) => void) => {
  return (
    <div className={styles.shortcutList}>
      {list.map((item) => (
        <div
          className={styles.item}
          key={item.id + type}
          onClick={!type && callback.bind(this, item)} // 快捷方式跳转对应iframe页面
        >
          <div className={styles.iconWrapper}>
            <span
              className={`${styles.icon} icon-${item.icon}`}
              style={{ color: type === 'ADD' ? '#6D6E78' : '#2387FA' }}
            />
            {type && (
              <span
                className={`${styles.operationIcon} icon-${type === 'ADD' ? 'home_tj' : 'home_sj'}`}
                onClick={callback.bind(this, item, parentId, tabIndex)}
              />
            )}
            {!type && item.num > 0 && (
              <span
                className={styles.numIcon}
              >
                {item.num}
              </span>
            )}
          </div>
          <span className={styles.name}>
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

@observer
export class QuickOperation extends React.Component<Partial<Props>> {
  componentDidMount() {
    this.props.store.getCurrentMenu();
  }

  render(): JSX.Element {
    const { menu, isShowEdit, currentEditMenu, menuGroup, toggleShortcutEdit, handleShortAdd, handleShortDelete, handleShortCutSave, handleShortCutOpenTab } = this.props.store;

    const items: TabsProps['items'] = menuGroup.map((item, index) => ({
      key: String(item.id),
      label: item.name,
      children: listPanel(item.menus, 'ADD', item.id, index, handleShortAdd),
    }));
    return (
      <>
        <div
          className={styles.quickOperation}
        >
          <div className={styles.quickOperationLeft}>
            <span className={styles.quickOperationText}>
              快捷操作
            </span>
            <div className={styles.quickOperationList}>
              {menu.map((item) => (
                <div
                  className={styles.item}
                  key={item.id}
                  onClick={handleShortCutOpenTab.bind(this, item)} // 快捷方式跳转对应iframe页面
                >

                  <i
                    className={`icon-${item.icon} ${styles.icon}`}
                  />
                  <span className={styles.name}>
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <span
            className={`${styles.icon} icon-home_bj`}
            onClick={toggleShortcutEdit.bind(this, true)}
          />
        </div>
        {isShowEdit && (
          <Modal
            centered
            closable={false}
            onCancel={toggleShortcutEdit.bind(this, false)}
            onOk={handleShortCutSave}
            open
            title="快捷操作"
            width={800}
            wrapClassName={styles.shortCurtModalWrapper}
          >
            <div className={styles.currentChoose}>
              {listPanel(currentEditMenu, 'DELETE', null, null, handleShortDelete)}
            </div>
            <div className={styles.reset}>
              <Tabs items={items}/>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
