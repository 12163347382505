import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, flow, observable } from 'mobx';
import { api } from '../../../utils';
import ShortCutStore from '../../components/shortcut/store';
import type { ArrearsInfo, HeaderStatistics, HeadOverview, ICoordination, SellerShopList } from './interface';

class DashboardStore {
  @observable public isShowHome = false; // 是否展示特殊首页（图片）

  @observable public totalStatic: HeaderStatistics[] = []; // 头部统计数据

  @observable public sellerShopId = ''; // 跳转到订单处理页携带的shopId

  @observable public arrearsInfo: ArrearsInfo = {
    arrearsCustomerNum: 0,
    arrearsPrice: '0',
  };

  @observable public brandData = {
    saleLackNum: 0,
    totalPlanNums: 0,
    currentTime: '',
  };

  @observable public coordinationData = {
    pickUpNum: 0,
    refundNum: 0,
    returnNum: 0,
  };// 协同数据

  @observable public deliverData = {
    aboutToOvertime: 0,
    unCheckToWarehouse: 0,
    unConfirmArrived: 0,
    unDelivered: 0,
  };// 代发数据

  @observable public shopData = {
    customerNumRatio: '',
    customerNumRatioFlag: 0,
    noPassGoodsNum: 0,
    reviewGoodsNum: 0,
    saleAmountRatio: '',
    saleAmountRatioFlag: 0,
    saleGoodsNum: 0,
    simpleGoodsNum: 0,
    skuNumRatio: '',
    skuNumRatioFlag: 0,
    todayCustomerNum: 0,
    todaySaleAmount: 0,
    todaySaleSkuNum: 0,
    yesterdayCustomerNum: 0,
    yesterdaySaleAmount: 0,
    yesterdaySaleSkuNum: 0,
  };// 依链小店数据

  @observable public posOrderData = {
    returnAmountFlag: 0,
    returnAmountRatio: '',
    returnAmountRatioFlag: 0,
    returnNumFlag: 0,
    returnOrderFlag: 0,
    returnOrderRatio: '',
    returnOrderRatioFlag: 0,
    returnRatio: '',
    returnRatioFlag: 0,
    saleAmountFlag: 0,
    saleAmountRatio: '',
    saleAmountRatioFlag: 0,
    saleNumFlag: 0,
    saleRatioFlag: 0,
    saleOrderNumFlag: 0,
    saleOrderRatio: '',
    saleOrderRatioFlag: 0,
    saleRatio: '',
    todayReturnAmount: 0,
    todayReturnNum: 0,
    todayReturnOrderNum: 0,
    todaySaleAmount: 0,
    todaySaleNum: 0,
    todaySaleOrderNum: 0,
    yesterdayReturnAmount: 0,
    yesterdayReturnNum: 0,
    yesterdayReturnOrderNum: 0,
    yesterdaySaleAmount: 0,
    yesterdaySaleNum: 0,
    yesterdaySaleOrderNum: 0,
  };// 开单数据

  @observable public shortCutStore = new ShortCutStore({ homePageType: 2 }); // 快捷入口

  @action public onQuery = (): void => {
    this.getIsShowHome();
    this.findRecentlyDataView();
    this.getArrearsInfo();
    this.getShopData();
    this.getOrderData();
    this.getCoordinationAndDeliver();
    this.queryClothesUnionData();
  };

  // 近七日数据总览
  @action public findRecentlyDataView = async(): Promise<void> => {
    const res: BaseData<HeadOverview> = await request({ url: `${api.findRecentlyDataView}` });
    const {
      newCustomerNum, putAwayDownloadGoodsNum, dynamicGoodsNum, selectedGoodsNum,
      posSaleOrderSkuNum, posSaleOrderPrice, mallSaleOrderPrice, mallSaleOrderSkuNum,
    } = res.data;
    this.totalStatic = [
      {
        title: '新增客户数',
        key: 'newCustomerNum',
        number: newCustomerNum,
      },
      {
        title: '上款款数',
        key: 'putAwayDownloadGoodsNum',
        number: putAwayDownloadGoodsNum,
      },
      {
        title: '推款款数',
        key: 'dynamicGoodsNum',
        number: dynamicGoodsNum,
      },
      {
        title: '选款量',
        key: 'selectedGoodsNum',
        number: selectedGoodsNum,
        hasTips: true,
      },
      {
        title: '开单销量',
        key: 'posSaleOrderSkuNum',
        number: posSaleOrderSkuNum,
      },
      {
        title: '开单销售额',
        key: 'posSaleOrderPrice',
        number: posSaleOrderPrice,
        unit: '元',
      },
      {
        title: '选款销量',
        key: 'mallSaleOrderSkuNum',
        number: mallSaleOrderSkuNum,
      },
      {
        title: '选款销售额',
        key: 'mallSaleOrderPrice',
        number: mallSaleOrderPrice,
        unit: '元',
      },
    ];
  };

  // 欠款信息
  @action public getArrearsInfo = async(): Promise<void> => {
    const res: BaseData<ArrearsInfo> = await request({ url: `${api.findArrearsInfo}` });
    this.arrearsInfo = res.data;
  };

  // 获取依链协同模块统计
  public getCoordinationAndDeliver = flow(function* () {
    const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];

    try {
      const corRes = yield request<BaseData<ICoordination>>({ url: `${api.getCoordination}/${shopId}` });
      this.coordinationData = corRes.data;

      const deliRes = yield request<BaseData>({ url: `${api.getPosDeliver}/${shopId}` });
      this.deliverData = deliRes.data;
    } catch (e) {
      console.log('获取协同代发模块数据错误', e);
    }
  });

  public getShopData = flow(function* () {
    const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];

    try {
      const shopRes = yield request<BaseData>({ url: `${api.getShopOperate}/${shopId}` });
      this.shopData = shopRes.data;
    } catch (e) {
      console.log(e);
    }
  });

  // 开单数据
  public getOrderData = flow(function* () {
    const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];
    try {
      const orderRes = yield request<BaseData>({ url: `${api.getPosOrder}/${shopId}` });
      this.posOrderData = orderRes.data;
    } catch (e) {
      console.log(e);
    }
  });

  // 查询当前用户是否展示特殊首页
  public getIsShowHome = async(): Promise<void> => {
    const res: BaseData<boolean> = await request({ url: `${api.getShowSpecialHome}` });
    this.isShowHome = res.data;
  };

  // 查询跳转到订单处理页携带的shopId
  @action public getSellerShopIdQuery = () => {
    request<BaseData<SellerShopList[]>>({ url: '/api/baseinfo/rest/shop/auth/list' }).then((res) => {
      const shopId = sessionStorage.getItem('currentWareHouse')?.split('-')[0];
      const newShopId = ((res.data || []).find((v) => v.sellerNick == shopId)?.shopId)?.toString();
      this.sellerShopId = newShopId || '';
    });
  };

  @action
  public queryClothesUnionData = async() => {
    const results = await request<BaseData<{
      saleLackNum: number;
      totalPlanNums: number;
      currentTime: string;
    }>>({ url: '/api/pos/baseinfo/rest/clothesUnion/queryClothesUnionData' });
    this.brandData = results.data;
  };
}

export default DashboardStore;
