import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.less';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { api } from '../../../../../utils';
import moment from 'moment';

interface PosExpireTenant {
  isComplete: number;// 是否完成充值
  contact: string;// 联系人
  mobile: string;// 联系电话
  remainDays: number;// 剩余到期天数
  expireTime: string;// 到期时间
}

const PosExpireTenantMsgModal = () => {
  const [
    visible,
    setVisible,
  ] = useState(false);
  const [
    posExpireData,
    setPosExpireData,
  ] = useState<PosExpireTenant>();
  useEffect(() => {
    const req = async() => {
      const res = await request<BaseData<PosExpireTenant>>({
        url: api.getPosExpireTenantMsg,
        method: 'GET',
      });
      setPosExpireData(res.data);
      setVisible(true);
    };

    const date = moment().format('YYYY-MM-DD');
    const advertisementModalDate = localStorage.getItem('PosExpireTenantMsgDate');
    if (advertisementModalDate) {
      if (date !== advertisementModalDate) {
        localStorage.setItem('PosExpireTenantMsgDate', date);
        req();
      }
    } else {
      localStorage.setItem('PosExpireTenantMsgDate', date);
      req();
    }
  }, []);

  const onClose = () => {
    setVisible(false);
    console.log('aaa');
  };
  if (!posExpireData || posExpireData.isComplete === null) {
    return null;
  }
  if (posExpireData.remainDays > 15) {
    return null;
  }
  return (
    <Modal
      centered
      maskClosable={false}
      onCancel={onClose}
      onOk={onClose}
      open={visible}
      title={!posExpireData.isComplete ? '未开通提醒' : posExpireData.remainDays === 0 ? '系统到期提醒' : 'POS到期提醒'}
      width={390}
    >
      <div className={styles.content}>
        <span>
          尊敬的用户：
        </span>
        <span>
          您好！
        </span>
        {!posExpireData.isComplete ? (
          <span>
            您的POS服务还未开通，如有疑问请联系：
          </span>
        ) : (
          <span>
            {posExpireData.remainDays === 0 ? (
              <span>
                POS系统已于
                <span className={styles.expire}>
                  （
                  {posExpireData.expireTime}
                  ）
                </span>
                到期，请尽快联系您的客户经理或者实施完成续费，避免影响您的日常使用。
              </span>
            ) : (
              <span>
                POS系统将于
                <span className={styles.expire}>
                  {posExpireData.remainDays}
                  天后
                  （
                  {posExpireData.expireTime}
                  ）
                </span>
                到期，请尽快联系您的客户经理或者实施完成续费，避免影响您的日常使用。
                <div>
                  若不续费，请尽快将你的开单数据，退货数据，库存数据等导出。到期后相关数据无法查看。
                </div>
              </span>
            )}
          </span>
        )}

        <span className={styles.mt20}>
          联系人：
          {posExpireData.contact}
        </span>
        <span>
          联系电话：
          <span className={styles.phone}>
            {posExpireData.mobile}
          </span>
        </span>
      </div>
    </Modal>

  );
};
export default PosExpireTenantMsgModal;
